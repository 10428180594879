import { useUser } from "@clerk/clerk-react";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { ICheckInputSingleLineViewModel } from "../../application/viewModels/CheckInputSingleLineViewModel";
import { useProjectStore, useUserStore } from "../../stores/project-store";

export default function useZebraLabelPrint() {
  const { user } = useUser();
  const [allowPrint, setAllowPrint] = useState(true);
  const { selectedProject } = useProjectStore();
  const { loggedInUser } = useUserStore();

  const [clerkUser, setUser] = useState<any>();

  async function getUser() {
    //@ts-ignore
    const token = await window.Clerk.session.getToken({
      template: "jwt-template-1",
    });

    const user = await fetch(
      `${process.env.REACT_APP_MANAGEMENT_URL}/api/users/${loggedInUser?.userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      },
    );
  }

  useEffect(() => {
    if (loggedInUser) {
      (async () => {
        const user = await getUser();

        setUser(user);
      })();
    }
  }, [loggedInUser]);

  const print = (zebraPrinter: any, approved: boolean) => {
    // alert(JSON.stringify(zebraPrinter));

    const zpl = `
^XA

^FO380,0^GFA,464,464,8,L07FE,K0JFE,J07KFC,I01MF,I03MFC,I0IF801IF,001FF8I01FF8,007FEK07FC,00FF8K01FE,01FEM0FF,01FCM03F8,03F8I04I01FC,07FJ04J0FE,0FEJ04J07E,0FCI03F8I03F,1F8I03F8I01F8,1FP01F8,3FK0EK0FC,3EK0EK0FC,3EI0C0E06I07C,7E003F8E3F8007E,7C003KF8003E,7C007KFC003E,7C3FF80E03FF83E,FC3FF80E03FF83F,F87C380E0387C3F,F838380E038381F,F83818I030381F,F838M0381F,F81C0C0E060701F,F80C1C0E0E0601F,F8040F1F0E0401F,F8003F3F1FI03F,FC0F3B001F9E03F,7C07L01C03E,7C0201IF00803E,7C007KFC007E,7E01FF001FF007E,3E018K03007C,3FJ07FCJ0FC,1F003KF800F8,1F80FFC07FE01F8,0FC04K0403F,0FCO03F,07EO07E,07FO0FE,03F8M01FC,01FEM03F8,00FFM0FF,007FCK01FE,003FFK07FC,001FFCI03FF8,I07IF0JF,I03MFC,J0MF,J03KFC,K03IFE,L01F8,^FS

^CF0,55
^FO010,000^FDPO: ${selectedProject?.code ?? "null"}^FS

^CF0,23

^FO010,060^FDBy: ${user?.firstName ?? "null"} ${user?.lastName ?? "null"}^FS

^CF0,30

^FO10,0100^FD#1 01-01-2024 10:15^FS
^FO380,0100^FD${approved ? "YES" : "NO"}^FS
^XZ
    `;
    //     const zpl = `
    // ^XA
    // ^FO380,10^GFA,464,464,8,L07FE,K0JFE,J07KFC,I01MF,I03MFC,I0IF801IF,001FF8I01FF8,007FEK07FC,00FF8K01FE,01FEM0FF,01FCM03F8,03F8I04I01FC,07FJ04J0FE,0FEJ04J07E,0FCI03F8I03F,1F8I03F8I01F8,1FP01F8,3FK0EK0FC,3EK0EK0FC,3EI0C0E06I07C,7E003F8E3F8007E,7C003KF8003E,7C007KFC003E,7C3FF80E03FF83E,FC3FF80E03FF83F,F87C380E0387C3F,F838380E038381F,F83818I030381F,F838M0381F,F81C0C0E060701F,F80C1C0E0E0601F,F8040F1F0E0401F,F8003F3F1FI03F,FC0F3B001F9E03F,7C07L01C03E,7C0201IF00803E,7C007KFC007E,7E01FF001FF007E,3E018K03007C,3FJ07FCJ0FC,1F003KF800F8,1F80FFC07FE01F8,0FC04K0403F,0FCO03F,07EO07E,07FO0FE,03F8M01FC,01FEM03F8,00FFM0FF,007FCK01FE,003FFK07FC,001FFCI03FF8,I07IF0JF,I03MFC,J0MF,J03KFC,K03IFE,L01F8,^FS

    // ^CF0,50
    // ^FO010,010^FDPO: 333333^FS
    // ^CFA,23
    // ^FO010,060^FDDrukker: John Doe^FS
    // ^FO10,0100^FD#1 01-01-2024 10:15 | X^FS
    // ^XZ
    //     `;
    //     const zpl = `
    // ^XA

    // ^FO380,10^GFA,464,464,8,L07FE,K0JFE,J07KFC,I01MF,I03MFC,I0IF801IF,001FF8I01FF8,007FEK07FC,00FF8K01FE,01FEM0FF,01FCM03F8,03F8I04I01FC,07FJ04J0FE,0FEJ04J07E,0FCI03F8I03F,1F8I03F8I01F8,1FP01F8,3FK0EK0FC,3EK0EK0FC,3EI0C0E06I07C,7E003F8E3F8007E,7C003KF8003E,7C007KFC003E,7C3FF80E03FF83E,FC3FF80E03FF83F,F87C380E0387C3F,F838380E038381F,F83818I030381F,F838M0381F,F81C0C0E060701F,F80C1C0E0E0601F,F8040F1F0E0401F,F8003F3F1FI03F,FC0F3B001F9E03F,7C07L01C03E,7C0201IF00803E,7C007KFC007E,7E01FF001FF007E,3E018K03007C,3FJ07FCJ0FC,1F003KF800F8,1F80FFC07FE01F8,0FC04K0403F,0FCO03F,07EO07E,07FO0FE,03F8M01FC,01FEM03F8,00FFM0FF,007FCK01FE,003FFK07FC,001FFCI03FF8,I07IF0JF,I03MFC,J0MF,J03KFC,K03IFE,L01F8,^FS

    // ^CFA,20
    // ^FO010,010^FDPO: ${selectedProject?.code ?? "null"}^FS
    // ^FO10,040^FDDoor: ${user?.firstName ?? "null"} ${user?.lastName ?? "null"}^FS
    // ^FO10,070^FD#1 01-01-2024 10:15^FS
    // ^FO10,100^FDGoedgekeurd^FS
    // ^XZ
    //     `;

    // const zpl = `^XA
    //               ^BY2,2,100
    //               ^FO20,20^BC^FD${serial}^FS
    //               ^XZ`;

    return new Promise((resolve, reject) => {
      zebraPrinter.send(
        zpl,
        () => {
          console.log("Printed succesfully.");
          resolve(true);
        },
        () => {
          console.log("Something went wrong trying to print.");
          setAllowPrint(true);
          reject();
        },
      );
    });
  };

  async function printLabel(approved: boolean) {
    //TODO: print using Zebra printer...
    return new Promise((resolve, reject) => {
      //@ts-ignore
      window.BrowserPrint.getDefaultDevice(
        "printer",
        (device: any) => {
          try {
            print(device, approved);
            resolve(true);
          } catch (e: unknown) {
            alert(
              "Er is iets fout gegaan met het printen probeer het opnieuw. (printLabel)",
            );
            setAllowPrint(true);
            reject();
          }
        },
        (error: any) => {
          console.log(error);
          setAllowPrint(true);
          reject();
        },
      );
    });
  }

  async function handlePrint(
    approved: boolean,
    check: ICheckInputSingleLineViewModel,
  ) {
    if (!allowPrint) {
      alert("Wacht 10 seconden voor de volgende print actie.");

      return;
    }

    try {
      setAllowPrint(false);

      setTimeout(() => {
        setAllowPrint(true);
      }, 10000);

      await printLabel(approved);

      //TODO: update check text and append new entry after printing the label succesfully.
      const entry = `${approved ? "approved;" : "not-approved;"} ${
        user?.fullName
      }; ${format(new Date(), "MM-dd-yyyy HH:mm:ss")}`;

      const previousEntries = check.text;

      await check.store(
        `${previousEntries ? previousEntries + "|" : ""}${entry}`,
      );
    } catch (e: unknown) {
      // setAllowPrint(true);
      alert(
        "Er is iets fout gegaan met het printen probeer het opnieuw. (handlePrint)",
      );
    }
  }

  return { handlePrint, allowPrint };
}
